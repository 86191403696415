<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="mb-4">
          <v-card-title>Trip Ticket Template</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6" v-if="!hasTripTicket">
                <upload-file
                  recordType="tripticket"
                  :recordId="1"
                  showSize
                  @onUpload="onUpload('tripTicketAttachments')"
                ></upload-file>
              </v-col>
              <v-col cols="12">
                <attachments
                  ref="tripTicketAttachments"
                  recordType="tripticket"
                  :recordId="1"
                  @onFiles="handleOnTripTicket"
                  @onFileDelete="refreshStore"
                ></attachments>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title>Trip Ticket Keywords</v-card-title>
          <v-card-text>
            <small>Click on a row to copy the keyword</small>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Value</th>
                    <th class="text-left">Keyword</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="item in tripTicketKeywords">
                    <tr :key="item.keyword" @click="copyToClipboard(item.keyword)">
                      <td>{{ item.value }}</td>
                      <td>{{ item.keyword }}</td>
                    </tr>
                    <template v-if="item.subTags">
                      <tr
                        v-for="subItem in item.subTags"
                        :key="`${item.keyword}-${subItem.keyword}`"
                        @click="copyToClipboard(subItem.keyword)"
                      >
                        <td class="pl-16">{{ subItem.value }}</td>
                        <td class="pl-16">{{ subItem.keyword }}</td>
                      </tr>
                    </template>
                  </template>
                  <p v-if="Object.keys(cffKeywords).length" class="font-weight-bold mt-4 mb-2">Custom Form Fields</p>
                  <template v-for="step in Object.keys(cffKeywords)">
                    <p :key="step" class="mb-0">{{ step }}</p>
                    <tr v-for="item in cffKeywords[step]" :key="item.keyword" @click="copyToClipboard(item.keyword)">
                      <td>{{ item.value }}</td>
                      <td>{{ item.keyword }}</td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="mb-4">
          <v-card-title>Permission Slip Template</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6" v-if="!hasPermissionSlipEnglish">
                <upload-file
                  recordType="permissionslip"
                  :recordId="1"
                  showSize
                  @onUpload="onUpload('permissionSlipEnglishAttachments')"
                ></upload-file>
              </v-col>
              <v-col cols="12">
                <attachments
                  ref="permissionSlipEnglishAttachments"
                  recordType="permissionslip"
                  :recordId="1"
                  @onFiles="handleOnPermissionSlipEnglish"
                  @onFileDelete="refreshStore"
                ></attachments>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" v-if="!hasPermissionSlipSpanish">
                <upload-file
                  recordType="permissionslip"
                  :recordId="2"
                  showSize
                  @onUpload="onUpload('permissionSlipSpanishAttachments')"
                ></upload-file>
              </v-col>
              <v-col cols="12">
                <attachments
                  ref="permissionSlipSpanishAttachments"
                  recordType="permissionslip"
                  :recordId="2"
                  @onFiles="handleOnPermissionSlipSpanish"
                  @onFileDelete="refreshStore"
                ></attachments>
              </v-col>
            </v-row>
            <v-row class="px-2" dense>
              <v-col cols="12" md="6">
                <v-subheader>Display Download Permission Slip button?</v-subheader>
                <v-radio-group v-model="templates.showDownloadPermissionSlip" row>
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title>Permission Slip Keywords</v-card-title>
          <v-card-text>
            <small>Click on a row to copy the keyword</small>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Value</th>
                    <th class="text-left">Keyword</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="item in permissionSlipKeywords">
                    <tr :key="item.keyword" @click="copyToClipboard(item.keyword)">
                      <td>{{ item.value }}</td>
                      <td>{{ item.keyword }}</td>
                    </tr>
                    <template v-if="item.subTags">
                      <tr
                        v-for="subItem in item.subTags"
                        :key="`${item.keyword}-${subItem.keyword}`"
                        @click="copyToClipboard(subItem.keyword)"
                      >
                        <td class="pl-16">{{ subItem.value }}</td>
                        <td class="pl-16">{{ subItem.keyword }}</td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  UPLOAD_TRIP_TICKET_TEMPLATE,
  DOWNLOAD_TRIP_TICKET_TEMPLATE,
  UPLOAD_PERMISSION_SLIP_TEMPLATE,
  DOWNLOAD_PERMISSION_SLIP_TEMPLATE,
} from '@/store/modules/TripRequest/actions.js';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import { CHECK_TEMPLATES } from '@/store/modules/App/actions';
import UploadFile from '@/components/UploadFile.vue';
import Attachments from '@/components/Attachments.vue';

export default {
  inject: ['eventHub'],
  components: { UploadFile, Attachments },
  data: () => ({
    templates: {
      showDownloadPermissionSlip: true,
    },
    hasTripTicket: false,
    hasPermissionSlipEnglish: false,
    hasPermissionSlipSpanish: false,
    tripTicketKeywords: [
      { value: 'District Name', keyword: '{districtName}' },
      { value: 'Requester Email', keyword: '{requesterEmail}' },
      { value: 'Trip Number', keyword: '{tripNumber}' },
      { value: 'Trip Date', keyword: '{tripDate}' },
      { value: 'Trip Depart (date & time)', keyword: '{tripDepart}' },
      { value: 'Trip Return (date & time)', keyword: '{tripReturn}' },
      { value: 'Venue Arrival (date & time)', keyword: '{venueArrival}' },
      { value: 'Venue Departure (date & time)', keyword: '{venueDeparture}' },
      { value: 'Trip Duration', keyword: '{tripDuration}' },
      { value: 'Trip Mileage', keyword: '{tripMileage}' },
      { value: 'Trip Events', keyword: '{tripEvents}' },
      { value: 'Trip Type', keyword: '{tripType}' },
      { value: 'Comments', keyword: '{comments}' },
      { value: 'Trip Location', keyword: '{tripLocation}' },
      { value: 'Requesting Location', keyword: '{requestingLocation}' },
      { value: 'Trip Destination', keyword: '{tripDestination}' },
      { value: 'Destination Address', keyword: '{destinationAddress}' },
      { value: 'Destination City', keyword: '{destinationCity}' },
      { value: 'Destination State', keyword: '{destinationState}' },
      { value: 'Destination Zip Code', keyword: '{destinationZip}' },
      { value: '# Adults', keyword: '{numAdults}' },
      { value: '# Students', keyword: '{numStudents}' },
      { value: 'Teacher Name', keyword: '{teacherName}' },
      { value: 'Teacher Phone', keyword: '{teacherPhone}' },
      { value: 'Emergency Contact Name', keyword: '{emergencyContactName}' },
      { value: 'Emergency Contact Phone', keyword: '{emergencyContactPhone}' },
      { value: 'Grade Levels', keyword: '{gradeLevels}' },
      { value: 'Special Indicators', keyword: '{specialIndicators}' },
      { value: 'Driver Last Name', keyword: '{driverLastName}' },
      { value: 'Driver First Name', keyword: '{driverFirstName}' },
      { value: 'Driver Email', keyword: '{driverEmail}' },
      { value: 'Driver ID', keyword: '{driverID}' },
      { value: 'Driver Phone', keyword: '{driverPhone}' },
      { value: 'Vehicle Driver Information', keyword: '{driverInfo}' },
      { value: 'Assistant Name', keyword: '{assistant}' },
      { value: 'Total Vehicles', keyword: '{totalVehicles}' },
      { value: 'Vehicle Name', keyword: '{vehicleName}' },
      { value: 'Vehicle Location', keyword: '{vehicleLocation}' },
      { value: 'Vehicle Type', keyword: '{vehicleType}' },
      { value: 'Vehicle Pickup Time', keyword: '{pickupTime}' },
      { value: 'Vehicle Return Time', keyword: '{returnTime}' },
      { value: 'Lift Needed', keyword: '{lift}' },
      { value: '# Wheelchairs', keyword: '{numWheelchair}' },
      { value: '# Safety Vests', keyword: '{numVests}' },
      { value: '# Fold Down Seats', keyword: '{numFoldDown}' },
      { value: 'Special Travel Needs', keyword: '{travelNeeds}' },
      { value: 'Special Instructions', keyword: '{specialInstructions}' },
      { value: 'Split Trip Indicator', keyword: '{splitTripIndicator}' },
      {
        value: 'Funding Sources',
        keyword: '{#fundingSources} {/fundingSources}',
        subTags: [
          { value: 'Funding Source', keyword: '{fundingSource}' },
          { value: 'Budget Code', keyword: '{budgetCode}' },
        ],
      },
      {
        value: 'Other Drivers/Vehicles',
        keyword: '{#otherAssignments} {/otherAssignments}',
        subTags: [
          { value: 'Driver Last Name', keyword: '{driverLastName}' },
          { value: 'Driver First Name', keyword: '{driverFirstName}' },
          { value: 'Driver Email', keyword: '{driverEmail}' },
          { value: 'Driver ID', keyword: '{driverID}' },
          { value: 'Driver Phone', keyword: '{driverPhone}' },
          { value: 'Assistant Name', keyword: '{assistant}' },
          { value: 'Vehicle Name', keyword: '{vehicleName}' },
        ],
      },
      {
        value: 'Trip Stops',
        keyword: '{#tripStops} {/tripStops}',
        subTags: [{ value: 'Stop Name', keyword: '{stopName}' }],
      },
      // {
      //   value: 'Roster Students',
      //   keyword: '{#rosterStudents} {/rosterStudents}',
      //   subTags: [{ value: 'Name', keyword: '{studentName}' }],
      // },
      // {
      //   value: 'Roster Teachers',
      //   keyword: '{#rosterTeachers} {/rosterTeachers}',
      //   subTags: [
      //     { value: 'Name', keyword: '{teacherName}' },
      //     { value: 'Email', keyword: '{teacherEmail}' },
      //     { value: 'Phone', keyword: '{teacherPhone}' },
      //   ],
      // },
    ],
    permissionSlipKeywords: [
      { value: 'District Name', keyword: '{districtName}' },
      { value: 'Trip Depart (date & time)', keyword: '{tripDepart}' },
      { value: 'Trip Return (date & time)', keyword: '{tripReturn}' },
      { value: 'Trip Type', keyword: '{tripType}' },
      { value: 'Trip Location', keyword: '{tripLocation}' },
      { value: 'Trip Destination', keyword: '{tripDestination}' },
      { value: 'Teacher Name', keyword: '{teacherName}' },
      { value: 'Vehicle Type', keyword: '{vehicleType}' },
      { value: 'Special Instructions', keyword: '{specialInstructions}' },
    ],
  }),
  computed: {
    ...mapGetters('config', ['cffConfig']),
    cffKeywords() {
      const steps = {
        0: 'General',
        1: 'Trip Leave / Return',
        2: 'Destination(s)',
        3: 'Attendees',
        4: 'Transportation',
        5: 'Funding',
        6: 'Additional Info',
        7: 'Supporting Documents',
      };
      const cffs = {};
      for (let step of Object.keys(this.cffConfig)) {
        if (this.cffConfig[step].length) {
          cffs[steps[step]] = [];
          for (let field of this.cffConfig[step])
            cffs[steps[step]].push({ value: field.name, keyword: `{CFF${field.id.replace(/[0-9]/g, 'X')}}` });
        }
      }
      return cffs;
    },
  },
  created() {
    this.eventHub.$on('saveTemplatesConfig', () => this.save());
  },
  beforeDestroy() {
    this.eventHub.$off('saveTemplatesConfig');
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('tripRequest', [
      UPLOAD_TRIP_TICKET_TEMPLATE,
      DOWNLOAD_TRIP_TICKET_TEMPLATE,
      UPLOAD_PERMISSION_SLIP_TEMPLATE,
      DOWNLOAD_PERMISSION_SLIP_TEMPLATE,
    ]),
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    ...mapActions('app', [CHECK_TEMPLATES]),
    async fetchItems() {
      this.trConfig = await this.getConfig('tripRequestForm');
      if (this.trConfig && this.trConfig.templates) this.templates = { ...this.templates, ...this.trConfig.templates };
    },
    async save() {
      try {
        const value = this.trConfig ? { ...this.trConfig, templates: this.templates } : { templates: this.templates };
        const r = await this.setConfig({ name: 'tripRequestForm', value });
        if (r && r.done) {
          this.$myalert.success('Settings saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    handleOnTripTicket(files) {
      this.hasTripTicket = !!files.length;
    },
    handleOnPermissionSlipEnglish(files) {
      this.hasPermissionSlipEnglish = !!files.length;
    },
    handleOnPermissionSlipSpanish(files) {
      this.hasPermissionSlipSpanish = !!files.length;
    },
    onUpload(which) {
      this.$refs[which].fetchItems();
      this.refreshStore();
    },
    async refreshStore() {
      await this.checkTemplates();
    },
    copyToClipboard(keyword) {
      navigator.clipboard.writeText(keyword);
      this.$myalert.success('Copied keyword to clipboard');
    },
  },
};
</script>

<style scoped></style>
